import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Text, AlertTitle, AlertDescription, Button } from '@chakra-ui/react';

function NotFound({ h, backTo }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToMain = () => {
    navigate(backTo);
  };
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      background="transparent"
      h={h}
    >
      <Text fontWeight="bold" fontSize="4xl">
        404
      </Text>
      <AlertTitle mb="2" fontSize="lg">
        {t('components.not_found.title', 'We are sorry, page not found!')}
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        {t(
          'components.not_found.content',
          'The page you are looking for might have been removed had its name changed or is temporarily unavailable',
        )}
      </AlertDescription>
      <Button size="sm" mt="4" onClick={navigateToMain}>
        {t('components.not_found.backToMain', 'Back to main page')}
      </Button>
    </Alert>
  );
}

NotFound.defaultProps = {
  h: '100%',
  backTo: '/',
};

NotFound.propTypes = {
  h: PropTypes.string,
  backTo: PropTypes.string,
};

export default NotFound;
