import { Box, Button, Center, Flex, HStack, Spacer, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ColorModeSwitcher from '../components/color-mode-switcher';
import LangSwitcher from '../components/lang-switcher';
import Logo from '../components/ncodefactory-logo';
import packageJson from '../../../package.json';
import { selectIsDev } from '../features/application/application-slice';

function Welcome() {
  const navigate = useNavigate();
  const navigateToPorphura = () => {
    navigate('/porphura');
  };
  const isDev = useSelector(selectIsDev);
  return (
    <Box h="100vh" overflow="hidden">
      <HStack pt="2px" pr="2px">
        <Spacer />
        <ColorModeSwitcher />
        <LangSwitcher />
      </HStack>
      <Center h="calc(100% - 80px)">
        <VStack w="100%">
          <Logo px="8" w={{ base: '100%', md: '90%', lg: '75%', xl: '50%' }} />
          <Button onClick={navigateToPorphura} size={{ base: 'xs', sm: 'sm', md: 'md', lg: 'lg' }} colorScheme="brand">
            Porphura™
          </Button>
        </VStack>
      </Center>
      <Flex h="40px" alignItems="flex-end">
        <Text color={useColorModeValue('brand.100', 'brand.800')} fontSize="xs" ml="3" mb="2">
          {`V${packageJson.version}`} {isDev ? 'DEV' : 'PROD'}
          {' MODE'}
        </Text>
      </Flex>
    </Box>
  );
}

export default Welcome;
