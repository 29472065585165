import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setLangSymbol } from '../features/application/application-slice';
import Tooltip from './tooltip';

function LangSwitcher({ variant }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  let tooltipLabel;
  let langSymbol;

  if (i18n.language.startsWith('en')) {
    tooltipLabel = i18n.t('components.lang_switcher.pl_tooltip', 'polish');
    langSymbol = 'PL';
  }

  if (i18n.language.startsWith('pl')) {
    tooltipLabel = i18n.t('components.lang_switcher.en_tooltip', 'english');
    langSymbol = 'EN';
  }

  const handleClick = (ls) => {
    i18n.changeLanguage(ls);
    dispatch(setLangSymbol(ls));
  };

  return (
    langSymbol && (
      <Tooltip mr="8px" label={tooltipLabel}>
        <Button
          colorScheme="gray"
          fontSize="sm"
          variant={variant}
          minH="40px"
          maxW="40px"
          size="sm"
          onClick={() => {
            handleClick((langSymbol ?? '').toLowerCase());
          }}
        >
          {langSymbol}
        </Button>
      </Tooltip>
    )
  );
}

LangSwitcher.defaultProps = {
  variant: 'ghost',
};

LangSwitcher.propTypes = {
  variant: PropTypes.string,
};
export default LangSwitcher;
