import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../../translations/i18n';

const initialState = {
  isBusy: false,
  isDev: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  langSymbol: i18n.language.startsWith('en') ? 'en' : 'pl',
  settings: { behavior: { autohideModuleMenu: false, disableTooltips: false } },
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setIsBusy: (state, action) => {
      const refState = state;
      refState.isBusy = action.payload;
    },
    setLangSymbol: (state, action) => {
      const refState = state;
      refState.langSymbol = action.payload;
    },
    setAutohideModuleMenu: (state, action) => {
      const refState = state;
      refState.settings.behavior.autohideModuleMenu = action.payload;
    },
    setDisableTooltips: (state, action) => {
      const refState = state;
      refState.settings.behavior.disableTooltips = action.payload;
    },
  },
});

const { setIsBusy, setLangSymbol, setAutohideModuleMenu, setDisableTooltips } = applicationSlice.actions;

const selectIsBusy = (state) => state.commonFeatures.application.isBusy;
const selectIsDev = (state) => state.commonFeatures.application.isDev;
const selectBehaviorSettings = (state) => state.commonFeatures.application.settings.behavior;

export {
  setIsBusy,
  setLangSymbol,
  selectIsBusy,
  selectIsDev,
  selectBehaviorSettings,
  setAutohideModuleMenu,
  setDisableTooltips,
};
export default applicationSlice.reducer;
