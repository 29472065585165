import { mode } from '@chakra-ui/theme-tools';
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const config = { initialColorMode: 'dark', useSystemColorMode: true };
const colors = {
  brand: {
    backgroundDark: '#1c0c28',
    backgroundLight: '#f4edf7',
    textDark: '#3b1f47',
    textLight: '#f4edf7',
    purpleDark: '#7a3f93',
    purpleLight: '#a295c3',
    50: '#f4edf7',
    100: '#e1cdea',
    200: '#ceaddc',
    300: '#bb8dce',
    400: '#a76dc0',
    500: '#944db3',
    600: '#773d8f',
    700: '#592e6b',
    800: '#3b1f47',
    900: '#1e0f24',
  },
  gray: {
    50: '#f4edf7',
    100: '#e1cdea',
    200: '#ceaddc',
    300: '#bb8dce',
    400: '#a76dc0',
    500: '#944db3',
    600: '#773d8f',
    700: '#592e6b',
    800: '#3b1f47',
    900: '#1e0f24',
  },
  silver: {
    50: '#f7fafc',
    100: '#edf2f7',
    200: '#e2e8f0',
    300: '#cbd5e0',
    400: '#a0aec0',
    500: '#718096',
    600: '#4a5568',
    700: '#2d3748',
    800: '#1a202c',
    900: '#171923',
  },
};

const styles = {
  global: (props) => ({
    body: {
      bg: mode('brand.backgroundLight', 'brand.backgroundDark')(props),
    },
    '*, *::before, &::after': {
      // borderColor: props.colorMode === 'dark' ? 'brand.800' : 'brand.300',
      // borderRadius: 'md',
    },
  }),
};

const Accordion = {
  // All parts of multipart components can be found in the @chakra-ui/anatomy package,
  // the menuAnatomy has as well these parts: button, list, groupTitle, command, divider
  parts: ['root', 'container', 'button', 'panel'],
  baseStyle: {
    root: {
      borderColor: 'transparent',
      pt: '1',
    },
    button: {
      pl: '1',
      py: '0',
    },
    container: {
      p: '0',
      pl: '0',
    },
    panel: {
      pl: '4',
      pb: '1',
    },
  },
};

const components = {
  Accordion,
  Button: {
    baseStyle: {
      _focus: {
        ring: 2,
        ringColor: 'brand.500',
      },
    },
  },
  Checkbox: {
    baseStyle: {
      control: {
        _focus: {
          ring: 2,
          ringColor: 'brand.500',
        },
      },
    },
  },
  Radio: {
    baseStyle: {
      control: {
        _focus: {
          ring: 2,
          ringColor: 'brand.500',
        },
      },
    },
  },
  Input: {
    defaultProps: {
      focusBorderColor: 'brand.500',
    },
  },
  Select: {
    defaultProps: {
      focusBorderColor: 'brand.500',
    },
  },
};
const zIndices = {
  // form: 0,
};

// const breakpoints = {
//   sm: '30em', // '480px'
//   md: '48em', // '768px'
//   lg: '62em', // '992px'
//   xl: '80em', // '1280px'
//   '2xl': '96em', // '1536px'
// };

const theme = extendTheme(
  { config, styles, colors, components, zIndices },
  withDefaultColorScheme({ colorScheme: 'brand', components: ['Checkbox', 'Radio'] }),
);

export default theme;
