import PropTypes from 'prop-types';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import theme from './theme';
import IndexPage from './pages';
import useEffectOnce from './hooks/use-effect-once';

function App({ hideLoader }) {
  useEffectOnce(hideLoader, []);

  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <IndexPage />
      </ChakraProvider>
    </>
  );
}

App.propTypes = {
  hideLoader: PropTypes.func.isRequired,
};

export default App;
