import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from './app/store';
import './translations/i18n';

const loader = document.querySelector('.loader');
const loaderText = document.querySelector('.loader-text');

// if you want to show the loader when React loads data again
const showLoader = () => {
  loader.classList.remove('loader--hide');
  loaderText.classList.remove('loader--hide');
};
const hideLoader = () => {
  loader.classList.add('loader--hide');
  loaderText.classList.add('loader--hide');
};

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App hideLoader={hideLoader} showLoader={showLoader} />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
