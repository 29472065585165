import PropTypes from 'prop-types';
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Tooltip from './tooltip';

function ColorModeSwitcher({ size, variant }) {
  const { toggleColorMode } = useColorMode();
  const { t } = useTranslation();
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const tooltipKey = useColorModeValue(
    'components.color_mode_switcher.dark_tooltip',
    'components.color_mode_switcher.light_tooltip',
  );
  const tooltipDefaultValue = useColorModeValue('dark', 'light');
  return (
    <Tooltip label={t(tooltipKey, tooltipDefaultValue)}>
      <IconButton colorScheme="gray" onClick={toggleColorMode} icon={<SwitchIcon />} variant={variant} size={size} />
    </Tooltip>
  );
}

ColorModeSwitcher.defaultProps = {
  size: 'md',
  variant: 'ghost',
};

ColorModeSwitcher.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default ColorModeSwitcher;
