import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import env from '../../../environment';

axios.defaults.baseURL = `/api/${env.USED_SERVER_API_VERSION}`;
axios.defaults.timeout = env.API_CLIENT_TIMEOUT;

const GET_SERVER_INFO_FETCH_STATUS = {
  UNKNOWN: 'unknown',
  IDLE: 'idle',
  LOADING: 'loading',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
};

const getServerInfo = createAsyncThunk('serverInfo/getServerInfo', async () => {
  const response = await axios.get('', { baseURL: '/api' });
  return response.data;
});

const initialState = {
  status: GET_SERVER_INFO_FETCH_STATUS.UNKNOWN,
  error: null,
  info: {},
};

const serverInfoSlice = createSlice({
  name: 'serverInfo',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      const refState = state;
      refState.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServerInfo.pending, (state) => {
        const refState = state;
        refState.status = GET_SERVER_INFO_FETCH_STATUS.LOADING;
        refState.error = null;
      })
      .addCase(getServerInfo.rejected, (state, action) => {
        const refState = state;
        refState.status = GET_SERVER_INFO_FETCH_STATUS.FAILED;
        refState.error = action.error.message;
      })
      .addCase(getServerInfo.fulfilled, (state, action) => {
        const refState = state;
        refState.status = GET_SERVER_INFO_FETCH_STATUS.SUCCEEDED;
        refState.info = action.payload;
        refState.error = null;
      });
  },
});

const { setStatus } = serverInfoSlice.actions;

const selectInfo = (state) => state.commonFeatures.serverInfo.info;
const selectStatus = (state) => state.commonFeatures.serverInfo.status;
const selectError = (state) => state.commonFeatures.serverInfo.error;

export { getServerInfo, selectInfo, selectError, selectStatus, setStatus, GET_SERVER_INFO_FETCH_STATUS };
export default serverInfoSlice.reducer;
