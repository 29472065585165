import PropTypes from 'prop-types';
import { Tooltip as ChTooltip } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectBehaviorSettings } from '../features/application/application-slice';

function Tooltip({ isDisabled, label, children }) {
  const { disableTooltips } = useSelector(selectBehaviorSettings);

  return (
    <ChTooltip isDisabled={isDisabled || disableTooltips} label={label}>
      {children}
    </ChTooltip>
  );
}

Tooltip.propTypes = {
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
Tooltip.defaultProps = {
  isDisabled: false,
};

export default Tooltip;
