import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Center, Heading, HStack, IconButton, Spacer, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { FaArrowRight, FaArrowCircleLeft } from 'react-icons/fa';
import ColorModeSwitcher from '../../components/color-mode-switcher';
import LangSwitcher from '../../components/lang-switcher';
import Tooltip from '../../components/tooltip';

function Hero() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToWelcome = () => {
    navigate('/');
  };
  const navigateToDemoOnline = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = 'https://porphura.ncodefactory.pl';
  };
  return (
    <VStack px="2px" pt="2px" h="100vh">
      <HStack w="100%">
        <Tooltip label={t('pages.porphura.hero.backToMainPage', 'back to welcome page')}>
          <IconButton colorScheme="gray" onClick={navigateToWelcome} variant="ghost" icon={<FaArrowCircleLeft />} />
        </Tooltip>
        <Spacer />
        <ColorModeSwitcher />
        <LangSwitcher />
      </HStack>
      <Center h="100%" pb="128px">
        <VStack>
          <Center maxW="40%">
            <Heading color="brand.500" size={['xl', '2xl', '2xl', '4xl', '4xl']}>
              Porphura™
            </Heading>
          </Center>
          <Spacer display={['none', 'none', 'block']} />
          <Heading pt="8px" size={['md', 'lg', 'xl', '2xl', '2xl']}>
            {t('pages.porphura.hero.headerFirstLine', 'Create large of business')}
          </Heading>
          <HStack>
            <Heading size={['md', 'lg', 'xl', '2xl', '2xl']}>
              {t('pages.porphura.hero.headerSecondLine', 'apps')}
            </Heading>
            <Spacer display={['none', 'none', 'block']} />
            <Spacer display={['none', 'none', 'block']} />
            <Heading size={['md', 'lg', 'xl', '2xl', '2xl']} color="brand.500">
              {t('pages.porphura.hero.headerSecondLineAccent', 'with speed')}
            </Heading>
          </HStack>
          <Spacer display={['none', 'none', 'block']} />
          <Text maxW="950px" px="16px" align="center" fontSize={['xs', 'sm', 'lg', '2xl']}>
            {t(
              'pages.porphura.hero.description',
              "Porphura™ is a fullstack platform for build modern lob's web applications for business, with the gui interface based on the simulation of windows of desktop systems",
            )}
          </Text>
          <Spacer display={['none', 'none', 'block']} />
          <HStack pt="16px">
            <Button size={['xs', 'sm', 'sm', 'md', 'lg']} rightIcon={<FaArrowRight />} disabled>
              {t('pages.porphura.hero.getStarted', 'Get started')}
            </Button>
            <Spacer display={['none', 'none', 'block']} />
            <Button isDisabled onClick={navigateToDemoOnline} size={['xs', 'sm', 'sm', 'md', 'lg']} colorScheme="brand">
              {t('pages.porphura.hero.viewDemoOnline', 'View demo online')}
            </Button>
          </HStack>
        </VStack>
      </Center>
      <Text opacity={useColorModeValue('0.4', '0.2')} fontSize="xs">
        {t('pages.porphura.hero.etymology', 'Porphura comes from the Greek (πορφύρα) and simply means purple')}
      </Text>
    </VStack>
  );
}

export default Hero;
