import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import applicationReducer from './features/application/application-slice';
import serverInfoReducer from './features/server-info/server-info-slice';
import confirmationReducer from './features/feedback/confirmation-slice';

function resolveReducers(dynamicReducers) {
  if (dynamicReducers == null || Object.keys(dynamicReducers).length === 0) {
    return dynamicReducers;
  }

  return { dynamicItems: combineReducers({ ...dynamicReducers }) };
}

const createReducer = (dynamicReducers) =>
  combineReducers({
    commonFeatures: combineReducers({
      application: applicationReducer,
      serverInfo: serverInfoReducer,
      confirmation: confirmationReducer,
    }),
    ...resolveReducers(dynamicReducers),
  });

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
});

store.dynamicReducers = {};
store.loadReducer = (key, reducer) => {
  if (store.dynamicReducers[key] == null && reducer != null) {
    store.dynamicReducers[key] = reducer;
    store.replaceReducer(createReducer(store.dynamicReducers));
  }

  return store;
};
store.unloadReducer = (key) => {
  if (store.dynamicReducers[key] != null) {
    delete store.dynamicReducers[key];
    store.replaceReducer(createReducer(store.dynamicReducers));
  }

  return store;
};

setupListeners(store.dispatch);

export default store;
