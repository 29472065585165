import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../../translations/i18n';

const initialState = {
  isOpen: false,
  header: i18n.t('features.feedback.confirmSlice.header', 'Confirm'),
  body: i18n.t('features.feedback.confirmSlice.body', 'Are you sure?'),
  confirmedAction: null,
};

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setProps: (state, action) => {
      const { header, body, confirmedAction, isOpen } = action.payload;
      const refState = state;
      refState.header = header || refState.header;
      refState.body = body || refState.body;
      refState.confirmedAction = confirmedAction;
      refState.isOpen = isOpen;
    },
    resetProps: (state) => {
      const { header, body, confirmedAction, isOpen } = initialState;
      const refState = state;
      refState.header = header;
      refState.body = body;
      refState.confirmedAction = confirmedAction;
      refState.isOpen = isOpen;
    },
    setIsOpen: (state, action) => {
      const refState = state;
      refState.isOpen = action.payload;
    },
  },
});

const { setProps, resetProps, setIsOpen } = confirmationSlice.actions;

const selectIsOpen = (state) => state.commonFeatures.confirmation.isOpen;
const selectProps = (state) => ({ ...state.commonFeatures.confirmation });

const confirm =
  ({ header, body, confirmedAction }) =>
  async (dispatch) => {
    dispatch(setProps({ header, body, confirmedAction }));
    dispatch(setIsOpen(true));
  };

export { selectIsOpen, selectProps, confirm, setIsOpen, resetProps };
export default confirmationSlice.reducer;
